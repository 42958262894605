<template>
    <div v-show="BuXiangQuMing" style="pointer-events: none;width: 100%;height: 100%;display: flex;justify-content: center;align-items: center;z-index: 9999;position: fixed;top: 0;">
        <div id="CF" style="font-size: 3vh;">{{ msg }}</div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            BuXiangQuMing: false,
            msg: ''
        }
    },

    methods: {
        ChuFa () {
            this.BuXiangQuMing = true
            this.msg = this.$parent.msg || '敬请期待'
            var targetElement = document.getElementById("CF");
            // 给元素添加类名
            targetElement.classList.add("JQQDBox");
            setTimeout(() => {
                this.BuXiangQuMing = false
                var element = document.getElementById("CF");
                element.classList.remove("JQQDBox");
            }, 2900)
        }
    }
}
</script>

<style scoped>
.JQQDBox {
    position: relative;
    border-radius: 5px;
    padding: 1.5vh 5vh;
    background-color: rgba(57, 175, 248, 0.863);
    color: rgb(246, 245, 242);
    animation-name: BottomJianTou;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}
@keyframes BottomJianTou {
    0% {
        top: 0rem;
    }

    100% {
        top: -5rem;
    }
}
</style>